import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiCard from "@mui/material/Card";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import API_BASE_URL from "../../../config";
import { login } from "../../../auth/login";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

export default function SignInCard() {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [usernameError, setUsernameError] = React.useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [loginError, setLoginError] = React.useState("");
  const [isSignUp, setIsSignUp] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const validateInputs = () => {
    let isValid = true;

    if (!username) {
      setUsernameError(true);
      setUsernameErrorMessage("Please enter your username.");
      isValid = false;
    } else {
      setUsernameError(false);
      setUsernameErrorMessage("");
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  const handleRegistration = async (userData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/register/`,
        userData
      );
      console.log("Sign up successful", response.data);
      setLoginError("Registration successful! You can now log in.");
    } catch (error) {
      console.error(
        "Registration error:",
        error.response?.data || error.message
      );
      if (error.response?.data) {
        const errorData = error.response.data;
        if (errorData.username) {
          setLoginError(`Username error: ${errorData.username[0]}`);
        } else if (errorData.email) {
          setLoginError(`Email error: ${errorData.email[0]}`);
        } else if (errorData.password) {
          setLoginError(`Password error: ${errorData.password[0]}`);
        } else {
          setLoginError(
            "An error occurred during registration. Please try again."
          );
        }
      } else {
        setLoginError(
          "An error occurred during registration. Please try again later."
        );
      }
    } finally {
      setIsSignUp(false); // Switch to login screen regardless of the outcome
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) return;

    setLoginError("");

    if (isSignUp) {
      setLoginError("Creating user account, please check your email");
      const userData = {
        username,
        email,
        password,
        password2: password,
        first_name: firstName,
        last_name: lastName,
      };
      handleRegistration(userData); // Start registration process asynchronously
    } else {
      try {
        const token = await login(username, password);
        console.log("Login successful");
        if (token) {
          navigate("/");
        }
      } catch (error) {
        console.error("Login error:", error.response?.data || error.message);
        if (error.response) {
          if (error.response.status === 400) {
            setLoginError("Incorrect username or password. Please try again.");
          } else if (error.response.data?.detail) {
            setLoginError(error.response.data.detail);
          } else {
            setLoginError(
              "Login failed. Please check your credentials and try again."
            );
          }
        } else if (error.request) {
          setLoginError(
            "Unable to reach the server. Please check your internet connection and try again."
          );
        } else {
          setLoginError(
            "An unexpected error occurred. Please try again later."
          );
        }
      }
    }
  };

  return (
    <Card variant="outlined">
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <img
          src="/Octozi_Logo_Light.png"
          alt="Octozi Logo"
          style={{ width: "100px", height: "auto" }}
        />
      </Box>{" "}
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
      >
        {isSignUp ? "Sign Up" : "Log in"}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}
      >
        {isSignUp && (
          <>
            <FormControl>
              <FormLabel htmlFor="username">Email</FormLabel>
              <TextField
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <TextField
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                fullWidth
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <TextField
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                fullWidth
              />
            </FormControl>
          </>
        )}
        <FormControl>
          <FormLabel htmlFor="username">Username</FormLabel>
          <TextField
            error={usernameError}
            helperText={usernameErrorMessage}
            id="username"
            type="text"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username"
            autoComplete="username"
            required
            fullWidth
            variant="outlined"
            color={usernameError ? "error" : "primary"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <TextField
            error={passwordError}
            helperText={passwordErrorMessage}
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="current-password"
            required
            fullWidth
            variant="outlined"
            color={passwordError ? "error" : "primary"}
          />
        </FormControl>
        {/* {!isSignUp && (
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
        )} */}
        {loginError && (
          <Typography color="error" sx={{ mt: 2 }}>
            {loginError}
          </Typography>
        )}
        <Button type="submit" fullWidth variant="contained">
          {isSignUp ? "Sign Up" : "Log in"}
        </Button>
        <Typography sx={{ textAlign: "center" }}>
          {isSignUp ? "Already have an account? " : "Don't have an account? "}
          <Link
            component="button"
            variant="body2"
            onClick={() => setIsSignUp(!isSignUp)}
          >
            {isSignUp ? "Log in" : "Sign up"}
          </Link>
        </Typography>
      </Box>
    </Card>
  );
}
