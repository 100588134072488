import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import WebIcon from "@mui/icons-material/Web";
import StorageIcon from "@mui/icons-material/Storage";
import VerifiedIcon from "@mui/icons-material/Verified";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

const items = [
  {
    icon: <VerifiedIcon sx={{ color: "text.secondary" }} />,
    title: "Advanced Reconciliation",
    description:
      "Octozi's proprietary reconciliation algorithms enable complex data cleaning tasks with ease.",
  },
  {
    icon: <StorageIcon sx={{ color: "text.secondary" }} />,
    title: "Data Aggregation",
    description:
      "Integrate all of your data sources into a single platform for a comprehensive view of your data.",
  },
  {
    icon: <WebIcon sx={{ color: "text.secondary" }} />,
    title: "Modern Interface",
    description:
      "Our user-friendly interface ensures that even non-technical users can navigate and use the platform with ease.",
  },
  {
    icon: <PublishedWithChangesIcon sx={{ color: "text.secondary" }} />,
    title: "Continuous Monitoring",
    description:
      "Our system continuously monitors your data for any changes or updates, ensuring that you always know the status of your data.",
  },
];

export default function Content() {
  // Add function to determine logo based on subdomain
  const getLogo = () => {
    const subdomain = window.location.hostname.split(".")[0];
    if (subdomain === "ionis") {
      return `/logos/${subdomain}_logo.png`;
    }
    return "/Octozi_Logo_Light.png";
  };

  return (
    <Stack
      sx={{
        flexDirection: "column",
        alignSelf: "center",
        gap: 4,
        maxWidth: 450,
      }}
    >
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <img
          src={getLogo()}
          alt="Company Logo"
          style={{ maxWidth: "60%", height: "auto" }}
        />
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography gutterBottom sx={{ fontWeight: "medium" }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}
