import React, { useEffect, useState } from "react";
import axios from "axios";
import API_BASE_URL from "./../../../../config";
import { useParams } from "react-router-dom";
import EditChecksTable from "../../../misc/EditChecksReport";
import PatientProfilePlots from "./PatientProfilePlots";

import {
  Button,
  Box,
  ButtonGroup,
  Card,
  CardContent,
  IconButton,
  Modal,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import InfoTooltip from "../../../misc/TooltipIcon";
import { CheckCircle, Warning, Error } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import "./AEReportPage.css";
import Plotly from "plotly.js-dist";

const AEEntryReportPage = () => {
  const { id } = useParams();
  const [entryReport, setEntryReport] = useState(null);
  const [activeTab, setActiveTab] = useState("AEReconciliationChecks");
  const [patientNarrative, setPatientNarrative] = useState("");
  const [patientNarrativeExists, setPatientNarrativeExists] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [medicalHistoryError, setMedicalHistoryError] = useState(null);
  const [entrySummary, setEntrySummary] = useState("");

  // console.log("entryReport", entryReport);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [open, setOpen] = useState(false);

  const [selectedReconciliationCheck, setSelectedReconciliationCheck] =
    useState(null);
  const [reconciliationCheckModalOpen, setReconciliationCheckModalOpen] =
    useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    handleButtonClick("Queried");
  };

  const handleQuerySubmit = () => {
    // Logic to submit the query
    // ...

    handleButtonClick("Queried");
    handleClose();
  };

  const handleOutsideClick = (event) => {
    if (event.target.id === "modal-wrapper") {
      setOpen(false);
    }
  };

  const handleButtonClick = async (status) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/ae-entry/${entryReport.id}/update-review-status/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({ review_status: status }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setEntryReport((prevState) => ({
          ...prevState,
          review_status: status,
        }));
        console.log(`Report status updated to: ${status}`);
      } else {
        console.error(
          "Failed to update report status:",
          result.detail || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error updating report status:", error.message);
    }
  };

  const fetchPatientNarrative = async (patientId) => {
    try {
      const response = await fetch(
        `/patient_profiles/patient_narrative_${patientId}.txt`
      );
      if (response.ok) {
        const text = await response.text();
        setPatientNarrative(text);
        setPatientNarrativeExists(true);
      } else {
        setPatientNarrative(
          "Patient narrative has not been generated for this patient."
        );
        setPatientNarrativeExists(false);
      }
    } catch (error) {
      console.error("Error fetching patient narrative:", error);
      setPatientNarrative("Error loading patient narrative.");
      setPatientNarrativeExists(false);
    }
  };

  const fetchMedicalHistory = async (patientId) => {
    try {
      const response = await fetch(
        `/patient_profiles/patient_medical_history_${patientId}.json`
      );
      if (response.ok) {
        const data = await response.json();
        setMedicalHistory(data);
        setMedicalHistoryError(null);
      } else {
        setMedicalHistory([]);
        setMedicalHistoryError(
          "Medical history data not available for this patient."
        );
      }
    } catch (error) {
      console.error("Error fetching medical history:", error);
      setMedicalHistory([]);
      setMedicalHistoryError("Error loading medical history data.");
    }
  };

  useEffect(() => {
    const fetchEntryReport = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/ae-entry/${id}/`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("authToken")}`,
            },
          }
        );
        setEntryReport(response.data);
        // After setting the entry report, fetch related data
        fetchPatientNarrative(response.data.patient_id);
        fetchMedicalHistory(response.data.patient_id);
      } catch (error) {
        console.error("Error fetching entry report:", error);
        setError("Failed to fetch entry report. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchEntryReport();
  }, [id]);

  useEffect(() => {
    const fetchEntrySummary = async () => {
      try {
        if (entryReport && entryReport.index !== undefined) {
          // Calculate the correct index for the file name
          const fileIndex = entryReport.index;
          const response = await fetch(
            `/ae_entry_summaries/entry_summary_${fileIndex}.txt`
          );
          if (response.ok) {
            const text = await response.text();
            setEntrySummary(
              text.trim() || "No generated summary created for this entry"
            );
          } else {
            throw new Error("Failed to fetch summary");
          }
        } else {
          setEntrySummary("Entry report data not available");
        }
      } catch (error) {
        console.error("Error fetching entry summary:", error);
        setEntrySummary("No generated summary created for this entry");
      }
    };

    if (entryReport !== null) {
      fetchEntrySummary();
    } else {
      setEntrySummary("Loading entry report data...");
    }
  }, [entryReport]);

  const getStatusIcon = (status) => {
    switch (status.toLowerCase()) {
      case "success":
        return <CheckCircle color="success" />;
      case "warning":
        return <Warning color="warning" />;
      case "error":
        return <Error color="error" />;
      default:
        return null;
    }
  };

  const renderSourceDataTable = (data) => {
    if (!data || data.length === 0) {
      return <Typography>No data available for this category.</Typography>;
    }

    // Filter out 'og_db_index' and 'ae_entry' from the columns
    const columns = Object.keys(data[0]).filter(
      (column) => column !== "og_db_index" && column !== "ae_entry"
    );

    return (
      <Box sx={{ width: "1300px", overflowX: "auto" }}>
        <TableContainer
          component={Paper}
          sx={{ minWidth: 650, maxHeight: 400 }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column} sx={{ whiteSpace: "nowrap" }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column} sx={{ whiteSpace: "nowrap" }}>
                      {row[column]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const handleReconciliationCheckClick = (check) => {
    setSelectedReconciliationCheck(check);
    setReconciliationCheckModalOpen(true);
  };

  const handleReconciliationCheckModalClose = () => {
    setReconciliationCheckModalOpen(false);
    setSelectedReconciliationCheck(null);
  };

  const renderReconciliationChecksTable = () => {
    if (
      !entryReport ||
      !entryReport.reconciliation_checks ||
      entryReport.reconciliation_checks.length === 0
    ) {
      return <Typography>No reconciliation checks available.</Typography>;
    }

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entryReport.reconciliation_checks.map((check, index) => (
              <TableRow
                key={index}
                onClick={() => handleReconciliationCheckClick(check)}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f5f5f5" },
                }}
              >
                <TableCell>{check.title}</TableCell>
                <TableCell>{getStatusIcon(check.status)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderReconciliationCheckModal = () => {
    if (!selectedReconciliationCheck) return null;

    return (
      <Dialog
        open={reconciliationCheckModalOpen}
        onClose={handleReconciliationCheckModalClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Reconciliation Check Details: {selectedReconciliationCheck.title}
          <IconButton
            aria-label="close"
            onClick={handleReconciliationCheckModalClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            Status: {selectedReconciliationCheck.status}
          </Typography>
          <Typography variant="body1" paragraph>
            Description: {selectedReconciliationCheck.description}
          </Typography>
          {selectedReconciliationCheck.discrepancy && (
            <Box>
              <Typography variant="h6">Discrepancy:</Typography>
              <Typography>
                Status: {selectedReconciliationCheck.discrepancy.status}
              </Typography>
              <Typography>
                Type: {selectedReconciliationCheck.discrepancy.type}
              </Typography>
              <Typography paragraph>
                Description:{" "}
                {selectedReconciliationCheck.discrepancy.description}
              </Typography>
              <Typography>
                Query Message:{" "}
                {selectedReconciliationCheck.discrepancy.query_message}
              </Typography>
            </Box>
          )}
          {/* Add more details as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReconciliationCheckModalClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "AEEditChecks":
        return (
          <div>
            <EditChecksTable aeEditChecks={entryReport.edit_checks} />
          </div>
        );
      case "AEReconciliationChecks":
        return (
          <div>
            {renderReconciliationChecksTable()}
            {renderReconciliationCheckModal()}
          </div>
        );
      case "Patient Profile":
        return (
          <div>
            <div>
              <h3>Generated Patient Narrative</h3>
              {patientNarrativeExists ? (
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    maxHeight: "100%",
                    overflowY: "auto",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  {patientNarrative}
                </pre>
              ) : (
                <p>{patientNarrative}</p>
              )}
            </div>
            <h3>Patient Profile Plots</h3>
            <PatientProfilePlots patientId={entryReport.patient_id} />
          </div>
        );
      case "Similar Cases":
        return (
          <div>
            <h2>Similar Cases</h2>
            <p>Similar Cases info</p>
          </div>
        );
      case "Cross-Database Entries":
        return (
          <div>
            <Typography variant="h6" gutterBottom>
              Adverse Event
            </Typography>
            {renderSourceDataTable([entryReport.entry_fields])}

            {[
              "ancillary_procedure_entries",
              "lab_entries",
              "concomitant_medications",
              "hospitalization_entries",
              "medical_history_entries",
              "vital_sign_entries",
            ].map((category) => {
              const title = category
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");

              return (
                <React.Fragment key={category}>
                  <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                    {title}
                  </Typography>
                  {entryReport[category] && entryReport[category].length > 0 ? (
                    renderSourceDataTable(entryReport[category])
                  ) : (
                    <Typography paragraph>
                      No {title} connected to this adverse event
                    </Typography>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      case "Medical History":
        return (
          <div>
            {medicalHistoryError ? (
              <Typography color="error">{medicalHistoryError}</Typography>
            ) : medicalHistory.length > 0 ? (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Medical History Term</TableCell>
                      <TableCell>
                        Start Day
                        <InfoTooltip tooltipText="Days Relative to Initial Study Visit Day" />
                      </TableCell>
                      <TableCell>
                        End Day
                        <InfoTooltip tooltipText="Days Relative to Initial Study Visit Day" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {medicalHistory.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.medical_history_term}</TableCell>
                        <TableCell>{item.medical_history_start_day}</TableCell>
                        <TableCell>
                          {item.medical_history_end_day === null
                            ? "Ongoing"
                            : item.medical_history_end_day}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>
                No medical history data available for this patient.
              </Typography>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!entryReport) {
    return <div>No data found for ID {id}</div>;
  }

  const getCheckStatus = (checks) => {
    let hasError = false;
    let hasWarning = false;

    for (const check of checks) {
      if (
        check.status.toLowerCase() === "error" ||
        (check.discrepancy &&
          check.discrepancy.status.toLowerCase() === "error")
      ) {
        hasError = true;
        break; // Exit early if an error is found
      }
      if (
        check.status.toLowerCase() === "warning" ||
        (check.discrepancy &&
          check.discrepancy.status.toLowerCase() === "warning")
      ) {
        hasWarning = true;
      }
    }

    return hasError ? "error" : hasWarning ? "warning" : "none";
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case "error":
        return <Error color="error" style={{ marginLeft: "5px" }} />;
      case "warning":
        return <Warning color="warning" style={{ marginLeft: "5px" }} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <h1>AE Entry Report Details</h1>
          <p>
            AE Entry ID: {entryReport.id} | Patient ID: {entryReport.patient_id}{" "}
            | Site ID: {entryReport.site_id} | AE medDRA code:{" "}
            {entryReport.entry_fields.aedecod} | Report Status:{" "}
            {entryReport.review_status} | Entry Date:{" "}
            {entryReport.date_of_creation.split("T")[0]} | Related to Treatment:{" "}
            {entryReport.related_to_treatment} | Severity Grade:{" "}
            {entryReport.severity_grade}
          </p>

          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <h3>Generated Entry Summary</h3>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxHeight: "200px",
                overflowY: "auto",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f8f8f8",
              }}
            >
              {entrySummary}
            </pre>
          </div>

          <div className="tabs">
            <button
              className={activeTab === "AEReconciliationChecks" ? "active" : ""}
              onClick={() => setActiveTab("AEReconciliationChecks")}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span>Clinical Consistency</span>
              {renderStatusIcon(
                getCheckStatus(entryReport.reconciliation_checks)
              )}
            </button>
            <button
              className={activeTab === "AEEditChecks" ? "active" : ""}
              onClick={() => setActiveTab("AEEditChecks")}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span>Edit Checks</span>
              {renderStatusIcon(getCheckStatus(entryReport.edit_checks))}
            </button>
            <button
              className={activeTab === "Patient Profile" ? "active" : ""}
              onClick={() => setActiveTab("Patient Profile")}
            >
              Patient Profile
            </button>
            <button
              className={activeTab === "Medical History" ? "active" : ""}
              onClick={() => setActiveTab("Medical History")}
            >
              Medical History
            </button>
            <button
              className={activeTab === "Cross-Database Entries" ? "active" : ""}
              onClick={() => setActiveTab("Cross-Database Entries")}
            >
              Cross-Database Entries
            </button>
          </div>
          <div className="tab-content">{renderContent()}</div>

          <div className="drawer-container">
            <ButtonGroup variant="contained" aria-label="Basic button group">
              <Button onClick={() => handleButtonClick("Reviewed")}>
                Mark Reviewed
              </Button>
              <Button onClick={() => handleButtonClick("On Hold")}>
                Mark On Hold
              </Button>
              {entryReport.review_status === "Queried" ? (
                <Button onClick={() => handleButtonClick("Unreviewed")}>
                  Mark Unreviewed
                </Button>
              ) : (
                <Button onClick={handleOpen}>Generate Query</Button>
              )}
            </ButtonGroup>
          </div>
        </CardContent>
      </Card>
      {entryReport.review_status !== "Queried" && (
        <Modal
          open={open}
          onClose={handleClose}
          onClick={handleOutsideClick}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            id="modal-wrapper"
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 1,
              outline: "none",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-title" variant="h6" component="h2">
              Generate Query
            </Typography>
            <TextField
              id="query-text"
              label="Query"
              defaultValue=""
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              sx={{ mt: 2 }}
            />
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button variant="contained" onClick={handleQuerySubmit}>
                Submit to EDC
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default AEEntryReportPage;
