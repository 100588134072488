import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./components/pages/reconciliation/HomePage";
import AEReconciliationPage from "./components/pages/reconciliation/AEReconciliationPage";
import ConMedReconciliationPage from "./components/pages/reconciliation/ConMedReconciliationPage";
import LabReconciliationPage from "./components/pages/reconciliation/LabReconciliationPage";
import VitalSignReconciliationPage from "./components/pages/reconciliation/VitalSignReconciliationPage";
import IECriteriaReconciliationPage from "./components/pages/reconciliation/IECriteriaReconciliationPage";
import ECGReconciliationPage from "./components/pages/reconciliation/ECGReconciliationPage";
import AEEntryReportPage from "./components/pages/entryReports/AEReportPage/AEReportPage";
import DocumentViewer from "./components/pages/StudyDocuments";
import SignInSide from "./components/pages/signIn/SignInSide";
import ProtectedRoute from "./components/protectedRoute";
import AccountPage from "./components/pages/account/AccountPage"; // Add this import
import { ProjectProvider } from "./contexts/ProjectContext";
import EmailConfirmation from "./components/pages/signIn/emailConfirmation";
import ReportNavigationPage from "./components/pages/reportPages/reportNavigationPage";
import IntegrationsPage from "./components/pages/IntegrationsPage";
import AuditLogPage from "./components/pages/auditpage/AuditLogPage";
import TreatmentEmergentTablePage from "./components/pages/reportPages/treatmentEmergentTablePage";
import SAENarrativesPage from "./components/pages/reportPages/SAENarrativesPage";
import NarrativeViewer from "./components/pages/reportPages/NarrativeViewer";

const AppRoutes = () => {
  return (
    <Router>
      <ProjectProvider>
        <Routes>
          <Route path="/login" element={<SignInSide />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route
                path="ae-reconciliation"
                element={<AEReconciliationPage />}
              />
              <Route
                path="ae-reconciliation/entry-report/:id"
                element={<AEEntryReportPage />}
              />
              <Route
                path="conmed-reconciliation"
                element={<ConMedReconciliationPage />}
              />
              <Route
                path="lab-reconciliation"
                element={<LabReconciliationPage />}
              />
              <Route
                path="vital-sign-reconciliation"
                element={<VitalSignReconciliationPage />}
              />
              <Route
                path="ie-criteria-reconciliation"
                element={<IECriteriaReconciliationPage />}
              />
              <Route
                path="ecg-reconciliation"
                element={<ECGReconciliationPage />}
              />
              <Route path="study-reports" element={<ReportNavigationPage />} />
              <Route
                path="reports/treatment-adverse-event-table"
                element={<TreatmentEmergentTablePage />}
              />
              <Route
                path="reports/sae-narratives"
                element={<SAENarrativesPage />}
              />
              <Route
                path="reports/sae-narratives/view/:id"
                element={<NarrativeViewer />}
              />
              <Route path="study-documents" element={<DocumentViewer />} />
              <Route path="account" element={<AccountPage />} />{" "}
              <Route path="integrations" element={<IntegrationsPage />} />
              <Route path="audit-log" element={<AuditLogPage />} />
            </Route>
          </Route>
          <Route path="/confirm-email/:token" element={<EmailConfirmation />} />
        </Routes>
      </ProjectProvider>
    </Router>
  );
};

export default AppRoutes;
