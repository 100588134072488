import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";

import axios from "axios";
import API_BASE_URL from "../../../config";

function TreatmentEmergentTablePage() {
  const [aeEntryFields, setAEEntryFields] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const fetchAEEntries = async () => {
      const token = localStorage.getItem("authToken");
      if (token) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/get_ae_entry_fields_data/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setAEEntryFields(response.data);

          // Initialize start and end dates
          if (response.data.length > 0) {
            const dates = response.data.map(
              (entry) => new Date(entry.date_of_creation)
            );
            const earliestDate = new Date(Math.min(...dates));
            const latestDate = new Date(Math.max(...dates));

            const formattedEarliestDate = earliestDate
              .toISOString()
              .split("T")[0];
            const formattedLatestDate = latestDate.toISOString().split("T")[0];

            setStartDate(formattedEarliestDate);
            setEndDate(formattedLatestDate);
            setMinDate(formattedEarliestDate);
            setMaxDate(formattedLatestDate);
          }
        } catch (error) {
          console.error("Error fetching AE Entry Fields Data:", error);
        }
      }
    };
    fetchAEEntries();
  }, []);

  console.log("aeEntryFields", aeEntryFields);

  const filterByDateRange = (entries) => {
    return entries.filter((entry) => {
      const creationDate = new Date(entry.date_of_creation);
      return (
        (!startDate || creationDate >= new Date(startDate)) &&
        (!endDate || creationDate <= new Date(endDate))
      );
    });
  };

  const filteredAEEntryFields = filterByDateRange(aeEntryFields);

  // Get all AEEntryField.aesevn equal to "Mild/Grade 1"
  const mildGrade1 = aeEntryFields.filter(
    (aeEntryField) => aeEntryField.aesevn === "Mild/Grade 1"
  );

  // Get all AEEntryField.aesevn equal to "Moderate/Grade 2"
  const moderateGrade2 = aeEntryFields.filter(
    (aeEntryField) => aeEntryField.aesevn === "Moderate/Grade 2"
  );

  // Get all AEEntryField.aesevn equal to "Severe/Grade 3"
  const severeGrade3 = aeEntryFields.filter(
    (aeEntryField) => aeEntryField.aesevn === "Severe/Grade 3"
  );

  // Get all AEEntryField.aesevn equal to "Life-threatening/Grade 4"
  const lifeThreateningGrade4 = aeEntryFields.filter(
    (aeEntryField) => aeEntryField.aesevn === "Life-threatening/Grade 4"
  );

  // Get all AEEntryField.aesevn equal to "Death/Grade 5"
  const deathGrade5 = aeEntryFields.filter(
    (aeEntryField) => aeEntryField.aesevn === "Death/Grade 5"
  );

  // Get total number of AEEntryFields
  const totalAEEntryFields = aeEntryFields.length;

  // Get total number of AEEntryFields with related relationship
  const totalAEEntryFieldsWithRelated = aeEntryFields.filter(
    (aeEntryField) => aeEntryField.aereln === "Suspected"
  ).length;

  // Get total number of AEEntryFields with not related relationship
  const totalAEEntryFieldsWithNotRelated = aeEntryFields.filter(
    (aeEntryField) => aeEntryField.aereln === "Not suspected"
  ).length;

  const totalFilteredAEEntryFields = filteredAEEntryFields.length;

  const generateCSV = () => {
    const headers = ["Category", "Total Number of AEs (%)", "Filtered AEs (%)"];
    const rows = [
      ["Severity", "", ""],
      [
        "Grade 1 (Mild)",
        `${mildGrade1.length} (${(
          (mildGrade1.length / totalAEEntryFields) *
          100
        ).toFixed(2)}%)`,
        `${filterByDateRange(mildGrade1).length} (${(
          (filterByDateRange(mildGrade1).length / totalFilteredAEEntryFields) *
          100
        ).toFixed(2)}%)`,
      ],
      [
        "Grade 2 (Moderate)",
        `${moderateGrade2.length} (${(
          (moderateGrade2.length / totalAEEntryFields) *
          100
        ).toFixed(2)}%)`,
        `${filterByDateRange(moderateGrade2).length} (${(
          (filterByDateRange(moderateGrade2).length /
            totalFilteredAEEntryFields) *
          100
        ).toFixed(2)}%)`,
      ],
      [
        "Grade 3 (Severe)",
        `${severeGrade3.length} (${(
          (severeGrade3.length / totalAEEntryFields) *
          100
        ).toFixed(2)}%)`,
        `${filterByDateRange(severeGrade3).length} (${(
          (filterByDateRange(severeGrade3).length /
            totalFilteredAEEntryFields) *
          100
        ).toFixed(2)}%)`,
      ],
      [
        "Grade 4 (Life-threatening)",
        `${lifeThreateningGrade4.length} (${(
          (lifeThreateningGrade4.length / totalAEEntryFields) *
          100
        ).toFixed(2)}%)`,
        `${filterByDateRange(lifeThreateningGrade4).length} (${(
          (filterByDateRange(lifeThreateningGrade4).length /
            totalFilteredAEEntryFields) *
          100
        ).toFixed(2)}%)`,
      ],
      [
        "Grade 5 (Death)",
        `${deathGrade5.length} (${(
          (deathGrade5.length / totalAEEntryFields) *
          100
        ).toFixed(2)}%)`,
        `${filterByDateRange(deathGrade5).length} (${(
          (filterByDateRange(deathGrade5).length / totalFilteredAEEntryFields) *
          100
        ).toFixed(2)}%)`,
      ],
      ["Relationship", "", ""],
      [
        "Related",
        `${totalAEEntryFieldsWithRelated} (${(
          (totalAEEntryFieldsWithRelated / totalAEEntryFields) *
          100
        ).toFixed(2)}%)`,
        `${
          filterByDateRange(
            aeEntryFields.filter(
              (aeEntryField) => aeEntryField.aereln === "Suspected"
            )
          ).length
        } (${(
          (filterByDateRange(
            aeEntryFields.filter(
              (aeEntryField) => aeEntryField.aereln === "Suspected"
            )
          ).length /
            totalFilteredAEEntryFields) *
          100
        ).toFixed(2)}%)`,
      ],
      [
        "Not Related",
        `${totalAEEntryFieldsWithNotRelated} (${(
          (totalAEEntryFieldsWithNotRelated / totalAEEntryFields) *
          100
        ).toFixed(2)}%)`,
        `${
          filterByDateRange(
            aeEntryFields.filter(
              (aeEntryField) => aeEntryField.aereln === "Not suspected"
            )
          ).length
        } (${(
          (filterByDateRange(
            aeEntryFields.filter(
              (aeEntryField) => aeEntryField.aereln === "Not suspected"
            )
          ).length /
            totalFilteredAEEntryFields) *
          100
        ).toFixed(2)}%)`,
      ],
    ];

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `Treatment Emergent Adverse Events ${startDate}-${endDate}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding="20px"
    >
      <Paper elevation={3} style={{ padding: "20px 20px 10px", width: "80%" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          All Treatment Emergent Adverse Events
        </Typography>
        <Box display="flex" justifyContent="center" gap={2} marginBottom="20px">
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: minDate, max: endDate }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: startDate, max: maxDate }}
          />
        </Box>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>N = {totalAEEntryFields}</TableCell>
                <TableCell>Total Number of AEs (%)</TableCell>
                <TableCell>Filtered AEs (%)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Severity</strong>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Grade 1 (Mild)</TableCell>
                <TableCell>
                  {mildGrade1.length} (
                  {((mildGrade1.length / totalAEEntryFields) * 100).toFixed(2)}
                  %)
                </TableCell>
                <TableCell>
                  {filterByDateRange(mildGrade1).length} (
                  {(
                    (filterByDateRange(mildGrade1).length /
                      totalFilteredAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Grade 2 (Moderate)</TableCell>
                <TableCell>
                  {moderateGrade2.length} (
                  {((moderateGrade2.length / totalAEEntryFields) * 100).toFixed(
                    2
                  )}
                  %)
                </TableCell>
                <TableCell>
                  {filterByDateRange(moderateGrade2).length} (
                  {(
                    (filterByDateRange(moderateGrade2).length /
                      totalFilteredAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Grade 3 (Severe)</TableCell>
                <TableCell>
                  {severeGrade3.length} (
                  {((severeGrade3.length / totalAEEntryFields) * 100).toFixed(
                    2
                  )}
                  %)
                </TableCell>
                <TableCell>
                  {filterByDateRange(severeGrade3).length} (
                  {(
                    (filterByDateRange(severeGrade3).length /
                      totalFilteredAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Grade 4 (Life-threatening)</TableCell>
                <TableCell>
                  {lifeThreateningGrade4.length} (
                  {(
                    (lifeThreateningGrade4.length / totalAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
                <TableCell>
                  {filterByDateRange(lifeThreateningGrade4).length} (
                  {(
                    (filterByDateRange(lifeThreateningGrade4).length /
                      totalFilteredAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Grade 5 (Death)</TableCell>
                <TableCell>
                  {deathGrade5.length} (
                  {((deathGrade5.length / totalAEEntryFields) * 100).toFixed(2)}
                  %)
                </TableCell>
                <TableCell>
                  {filterByDateRange(deathGrade5).length} (
                  {(
                    (filterByDateRange(deathGrade5).length /
                      totalFilteredAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Relationship</strong>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Related</TableCell>
                <TableCell>
                  {totalAEEntryFieldsWithRelated} (
                  {(
                    (totalAEEntryFieldsWithRelated / totalAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
                <TableCell>
                  {
                    filterByDateRange(
                      aeEntryFields.filter(
                        (aeEntryField) => aeEntryField.aereln === "Suspected"
                      )
                    ).length
                  }{" "}
                  (
                  {(
                    (filterByDateRange(
                      aeEntryFields.filter(
                        (aeEntryField) => aeEntryField.aereln === "Suspected"
                      )
                    ).length /
                      totalFilteredAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Not Related</TableCell>
                <TableCell>
                  {totalAEEntryFieldsWithNotRelated} (
                  {(
                    (totalAEEntryFieldsWithNotRelated / totalAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
                <TableCell>
                  {
                    filterByDateRange(
                      aeEntryFields.filter(
                        (aeEntryField) =>
                          aeEntryField.aereln === "Not suspected"
                      )
                    ).length
                  }{" "}
                  (
                  {(
                    (filterByDateRange(
                      aeEntryFields.filter(
                        (aeEntryField) =>
                          aeEntryField.aereln === "Not suspected"
                      )
                    ).length /
                      totalFilteredAEEntryFields) *
                    100
                  ).toFixed(2)}
                  %)
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="flex-end" marginTop="10px">
          <Button variant="contained" color="primary" onClick={generateCSV}>
            Generate CSV
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default TreatmentEmergentTablePage;
